import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import ContactForm from "../components/contact-form";
import Footer from "../components/footer";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact-container1">
      <Helmet>
        <title>
          Contact - Affordable Custom Solar Systems | Gridhack Solar
        </title>
        <meta
          name="description"
          content="Save on energy bills with PPAs and solar loan options. Our goal is to make solar power accessible, affordable, and create a sustainable future for all."
        />
        <meta
          property="og:title"
          content="Contact - Affordable Custom Solar Systems | Gridhack Solar"
        />
        <meta
          property="og:description"
          content="Save on energy bills with PPAs and solar loan options. Our goal is to make solar power accessible, affordable, and create a sustainable future for all."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/03f42d1d-03f9-45c6-8296-041753989070/fa7cb9e8-4d19-483d-8664-392a15595b0b?org_if_sml=1&amp;force_format=original"
        />
        <meta property="og:url" content="https://yourwebsite.com/contact" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content="Contact - Affordable Custom Solar Systems | Gridhack Solar"
        />
        <meta
          property="twitter:description"
          content="Save on energy bills with PPAs and solar loan options. Our goal is to make solar power accessible, affordable, and create a sustainable future for all."
        />
        <meta
          property="twitter:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/03f42d1d-03f9-45c6-8296-041753989070/fa7cb9e8-4d19-483d-8664-392a15595b0b?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="contact-header-containde">
        <header className="contact-header">
          <Navbar
            text={
              <Fragment>
                <span className="contact-text10">
                  Learn more: (Coming Soon)
                </span>
              </Fragment>
            }
            text1={
              <Fragment>
                <span className="contact-text11">...</span>
              </Fragment>
            }
            text2={
              <Fragment>
                <span className="contact-text12">...</span>
              </Fragment>
            }
            text3={
              <Fragment>
                <span className="contact-text13">...</span>
              </Fragment>
            }
            text4={
              <Fragment>
                <Link to="/" className="contact-navlink">
                  <span>Home</span>
                  <br></br>
                </Link>
              </Fragment>
            }
            text5={
              <Fragment>
                <span className="contact-text16">Contact</span>
              </Fragment>
            }
            text6={
              <Fragment>
                <span className="contact-text17">About Us</span>
              </Fragment>
            }
          />
        </header>
      </div>
      <img
        alt="image"
        src="https://images.unsplash.com/photo-1503343870946-44cccad5de1b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQxfHxzb2xhcnxlbnwwfHx8fDE3MjU0MjY1NzJ8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
        loading="eager"
        className="contact-image1"
      />
      <ContactForm
        action={
          <Fragment>
            <span className="contact-text18 thq-body-small">Contact Us</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="contact-text19 thq-body-small">
              We’re here to help you make the switch to clean, renewable energy.
              Whether you have questions about our solar solutions, want to
              learn more about our Power Purchase Agreements (PPAs), or need
              assistance with financing options, our team is ready to assist
              you.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="contact-text20 thq-heading-2">Get in Touch</span>
          </Fragment>
        }
        rootClassName="contact-formroot-class-name"
      />
      <div className="contact-report">
        <div className="contact-container2">
          <h2 className="contact-heading">
            <span>
              Unlock Your
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="contact-text22">Free</span>
            <span></span>
            <span className="contact-text24">Solar Savings Report</span>
            <br></br>
          </h2>
          <p className="contact-caption">
            Curious about how much you could save by going solar? Our
            personalized savings report is designed to give you a clear picture
            of the financial benefits you can expect. Here’s how it works:
          </p>
        </div>
        <section className="contact-experience">
          <div className="contact-container3">
            <div className="contact-container4">
              <div className="contact-images">
                <div className="contact-square"></div>
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1625834318071-f28f0e51449b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMyfHxyZXBvcnR8ZW58MHx8fHwxNzI1NDI2NDMyfDA&amp;ixlib=rb-4.0.3&amp;w=800"
                  className="contact-image2"
                />
              </div>
            </div>
          </div>
          <div className="contact-content">
            <div className="contact-checkmarks">
              <div className="contact-check1">
                <span className="contact-text26">
                  <span>
                    Assessment of Your Energy Usage:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <br></br>
                  <span className="contact-text29">
                    We start by gathering information about your current
                    electricity consumption. This helps us understand your
                    energy needs and identify the best solar solution for your
                    home or business.
                  </span>
                </span>
              </div>
              <div className="contact-check2">
                <span className="contact-text30">
                  <span>Customized Solar Design:</span>
                  <br></br>
                  <span className="contact-text33">
                    Using advanced software, we design a solar system tailored
                    to your specific needs, taking into account your roof space,
                    orientation, and local sun exposure.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="contact-check3">
                <span className="contact-text35">
                  <span>
                    Detailed Financial Analysis:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <br></br>
                  <span className="contact-text38">
                    We calculate your potential savings by comparing your
                    current energy costs with what you would pay after
                    installing solar panels. This includes any available
                    incentives, tax credits, and financing options.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="contact-check4">
                <span className="contact-text40">
                  <span>
                    Transparent Results:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <br></br>
                  <span className="contact-text43">
                    Our savings report provides a clear breakdown of your
                    potential savings over time, helping you make an informed
                    decision about switching to solar.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer rootClassName="footerroot-class-name1" />
    </div>
  );
};

export default Contact;
