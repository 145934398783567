import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./customer.css";

const Customer = (props) => {
  return (
    <section className={`customer-customer ${props.rootClassName} `}>
      <div className="customer-header">
        <h2 className="customer-heading1">
          {props.heading ?? (
            <Fragment>
              <h2 className="customer-heading2">
                <span className="customer-text20">
                  Testimonials: Powered by Solar,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="customer-text21">Inspired by You</span>
                <br></br>
              </h2>
            </Fragment>
          )}
        </h2>
      </div>
      <div className="customer-quotes">
        <div className="customer-quote1">
          <p className="customer-quote2">
            {props.quote ?? (
              <Fragment>
                <p className="customer-quote8">
                  <span>
                    &quot;Von was incredibly helpful throughout the entire
                    process, from applying for my solar panel system to the
                    final installation. He handled all the legwork, including
                    coordinating with my HOA and CPS to ensure everything was
                    inspected and approved. The system works flawlessly, and
                    I&apos;ve already noticed a significant drop in my electric
                    bill. I would highly recommend him to anyone!&quot;
                  </span>
                  <br></br>
                </p>
              </Fragment>
            )}
          </p>
          <div className="customer-author1">
            <span className="customer-name1">
              {props.name ?? (
                <Fragment>
                  <span className="customer-text17">Caitlin Sutherland</span>
                </Fragment>
              )}
            </span>
            <span className="customer-location1">
              {props.location ?? (
                <Fragment>
                  <span className="customer-text13">Customer</span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="customer-quote3">
          <p className="customer-quote4">
            {props.quote1 ?? (
              <Fragment>
                <p className="customer-quote9">
                  <span className="customer-text23">
                    &quot;Great company to work with! They&apos;ve installed
                    solar for a couple of my referrals, and the experience has
                    been fantastic. Von has been incredibly supportive, driven
                    by his commitment to saving the planet and his charitable
                    efforts.&quot;
                  </span>
                  <br></br>
                </p>
              </Fragment>
            )}
          </p>
          <div className="customer-author2">
            <span className="customer-name2">
              {props.name1 ?? (
                <Fragment>
                  <span className="customer-text19">Lulani Dennehy</span>
                </Fragment>
              )}
            </span>
            <span className="customer-location2">
              {props.location1 ?? (
                <Fragment>
                  <span className="customer-text12">Referral</span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="customer-quote5">
          <p className="customer-quote6">
            {props.quote2 ?? (
              <Fragment>
                <p className="customer-quote7">
                  <span className="customer-text10">
                    &quot;A much better option than anything else I was quoted.
                    I was very skeptical about solar companies until I met the
                    team—they walked me through every detail. Customer support
                    is a 10/10. Highly recommend speaking with Arianna!&quot;
                  </span>
                  <br></br>
                </p>
              </Fragment>
            )}
          </p>
          <div className="customer-author3">
            <span className="customer-name3">
              {props.name2 ?? (
                <Fragment>
                  <span className="customer-text18">Jack Meifert</span>
                </Fragment>
              )}
            </span>
            <span className="customer-location3">
              {props.location2 ?? (
                <Fragment>
                  <span className="customer-text14">Customer</span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

Customer.defaultProps = {
  quote2: undefined,
  rootClassName: "",
  location1: undefined,
  location: undefined,
  location2: undefined,
  quote: undefined,
  name: undefined,
  name2: undefined,
  name1: undefined,
  heading: undefined,
  quote1: undefined,
};

Customer.propTypes = {
  quote2: PropTypes.element,
  rootClassName: PropTypes.string,
  location1: PropTypes.element,
  location: PropTypes.element,
  location2: PropTypes.element,
  quote: PropTypes.element,
  name: PropTypes.element,
  name2: PropTypes.element,
  name1: PropTypes.element,
  heading: PropTypes.element,
  quote1: PropTypes.element,
};

export default Customer;
