import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./mission.css";

const Mission = (props) => {
  return (
    <div className={`mission-mission ${props.rootClassName} `}>
      <div className="mission-container1">
        <h2 className="mission-heading1">
          {props.heading ?? (
            <Fragment>
              <h2 className="mission-heading2">
                <span>
                  Empowering Communities for a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="mission-text22">Sustainable Future</span>
                <br></br>
              </h2>
            </Fragment>
          )}
        </h2>
      </div>
      <section className="mission-experience">
        <div className="mission-container2">
          <div className="mission-container3">
            <div className="mission-images">
              <div className="mission-square"></div>
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="mission-image"
              />
            </div>
          </div>
        </div>
        <div className="mission-content1">
          <div className="mission-header">
            <div className="mission-header-container">
              <p className="mission-caption">
                {props.caption ?? (
                  <Fragment>
                    <p className="mission-text30">
                      We strive to create a sustainable and brighter future for
                      all. Our focus is on providing affordable and efficient
                      solar solutions to communities worldwide, helping to light
                      up lives and protect our planet.
                    </p>
                  </Fragment>
                )}
              </p>
            </div>
            <div className="mission-checkmarks">
              <div className="mission-check1">
                <div className="mission-mark1">
                  <svg viewBox="0 0 1024 1024" className="mission-icon1">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span>
                  {props.text2 ?? (
                    <Fragment>
                      <span className="mission-text16">
                        Committed to delivering clean energy solutions
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="mission-check2">
                <div className="mission-mark2">
                  <svg viewBox="0 0 1024 1024" className="mission-icon3">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span>
                  {props.text3 ?? (
                    <Fragment>
                      <span className="mission-text24">
                        <span>
                          Empowering communities through renewable energy
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="mission-check3">
                <div className="mission-mark3">
                  <svg viewBox="0 0 1024 1024" className="mission-icon5">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span>
                  {props.text4 ?? (
                    <Fragment>
                      <span className="mission-text27">
                        <span>
                          Supporting families with essential resources
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="mission-check4">
                <div className="mission-mark4">
                  <svg viewBox="0 0 1024 1024" className="mission-icon7">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span>
                  {props.text5 ?? (
                    <Fragment>
                      <span className="mission-text18">
                        <span>
                          Building resilient shelters for future natural
                          disasters
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="mission-testimonial">
            <div className="mission-content2">
              <span className="mission-text14">
                {props.text ?? (
                  <Fragment>
                    <span className="mission-text17">Testimonial</span>
                  </Fragment>
                )}
              </span>
              <p>
                {props.text1 ?? (
                  <Fragment>
                    <p className="mission-text31">
                      &quot;The quickest and easiest way I&apos;ve ever saved
                      money! Amanda was amazing and incredibly helpful. I love
                      how my home operates now!&quot;
                    </p>
                  </Fragment>
                )}
              </p>
            </div>
            <div className="mission-information">
              <div className="mission-author">
                <img
                  alt={props.iconAlt}
                  src={props.iconSrc}
                  className="mission-icon9"
                />
                <span className="mission-name">
                  {props.name ?? (
                    <Fragment>
                      <span className="mission-text32">Robert Robledo</span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Mission.defaultProps = {
  iconSrc:
    "https://images.unsplash.com/photo-1620401537422-905dd2ec07e7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYwfHxvbGRlciUyMG1leGljYW4lMjBtYW58ZW58MHx8fHwxNzI1NDc1NzQ5fDA&ixlib=rb-4.0.3&w=200",
  text2: undefined,
  text: undefined,
  text5: undefined,
  rootClassName: "",
  imageSrc:
    "https://images.unsplash.com/photo-1724041875334-0a6397111c7e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDh8fHBob3Rvdm9sdGFpY3xlbnwwfHx8fDE3MjU1OTQwNzl8MA&ixlib=rb-4.0.3&w=700",
  heading: undefined,
  text3: undefined,
  text4: undefined,
  caption: undefined,
  imageAlt: "image",
  text1: undefined,
  name: undefined,
  iconAlt: "image",
};

Mission.propTypes = {
  iconSrc: PropTypes.string,
  text2: PropTypes.element,
  text: PropTypes.element,
  text5: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  heading: PropTypes.element,
  text3: PropTypes.element,
  text4: PropTypes.element,
  caption: PropTypes.element,
  imageAlt: PropTypes.string,
  text1: PropTypes.element,
  name: PropTypes.element,
  iconAlt: PropTypes.string,
};

export default Mission;
