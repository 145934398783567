import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Mission from "../components/mission";
import Footer from "../components/footer";
import "./about-us.css";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Helmet>
        <title>
          About Us - Affordable Custom Solar Systems | Gridhack Solar
        </title>
        <meta
          name="description"
          content="Learn about Gridhack Solar's commitment to providing affordable and sustainable solar solutions. Discover our mission, achievements, and the impact we make in the renewable energy sector."
        />
        <meta
          property="og:title"
          content="About Us - Affordable Custom Solar Systems | Gridhack Solar"
        />
        <meta
          property="og:description"
          content="Learn about Gridhack Solar's commitment to providing affordable and sustainable solar solutions. Discover our mission, achievements, and the impact we make in the renewable energy sector."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/03f42d1d-03f9-45c6-8296-041753989070/fa7cb9e8-4d19-483d-8664-392a15595b0b?org_if_sml=1&amp;force_format=original"
        />
        <meta
          property="og:url"
          content="https://www.gridhacksolar.com/about-us"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="about-us-header-container1">
        <header className="about-us-header1">
          <Navbar
            text={
              <Fragment>
                <span className="about-us-text10">
                  Learn more: (Coming Soon)
                </span>
              </Fragment>
            }
            text1={
              <Fragment>
                <span className="about-us-text11">...</span>
              </Fragment>
            }
            text2={
              <Fragment>
                <span className="about-us-text12">...</span>
              </Fragment>
            }
            text3={
              <Fragment>
                <span className="about-us-text13">...</span>
              </Fragment>
            }
            text4={
              <Fragment>
                <Link to="/" className="about-us-navlink">
                  <span>Home</span>
                  <br></br>
                </Link>
              </Fragment>
            }
            text5={
              <Fragment>
                <span className="about-us-text16">Contact</span>
              </Fragment>
            }
            text6={
              <Fragment>
                <span className="about-us-text17">About Us</span>
              </Fragment>
            }
          ></Navbar>
        </header>
      </div>
      <section className="about-us-hero">
        <img
          alt="image"
          src="https://images.unsplash.com/photo-1559302504-64aae6ca6b6d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI3fHxzb2xhciUyMHRlYW18ZW58MHx8fHwxNzI1NDg4NzE0fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
          className="about-us-image"
        />
        <div className="about-us-hero-content">
          <div className="about-us-header-container2">
            <div className="about-us-header2">
              <h1 id="hero-title" className="about-us-heading1">
                <span>
                  Dedicated to Your
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="about-us-text19">Solar Success</span>
                <br></br>
              </h1>
            </div>
            <p className="about-us-caption1">
              <span>
                We’re committed to making a positive impact on your energy
              </span>
              <span>needs and the environment.</span>
            </p>
          </div>
        </div>
      </section>
      <div className="about-us-mission">
        <div className="about-us-header3">
          <h2 className="about-us-heading2">
            <span>Our Mission</span>
            <br></br>
          </h2>
        </div>
        <section className="about-us-statement">
          <p className="about-us-caption2">
            We are a dedicated solar energy company, driven by a mission to
            create a brighter, more sustainable future. Harnessing the sun’s
            limitless power, we offer affordable and efficient solar solutions
            to communities worldwide. Join us in our commitment to light up
            lives and protect our planet, one solar panel at a time. Together,
            we can make a meaningful difference. Our charity efforts focus on
            providing food, resources, free energy, and essential shelter for
            future natural disasters through our cybercafes.
          </p>
        </section>
      </div>
      <section className="about-us-statistics">
        <div className="about-us-content">
          <div className="about-us-stat1">
            <h3 className="about-us-header4">100+</h3>
            <span className="about-us-caption3">
              <span className="about-us-text25">Projects Completed</span>
              <br></br>
            </span>
            <span className="about-us-caption4">
              At our local North Colorado company, Projects & Customer Service
              are our top priorities. Every project receives the expert
              attention and care it deserves. We partner with Tephra Solar to
              bring decentralized power solutions to life.
            </span>
          </div>
          <div className="about-us-stat2">
            <h3 className="about-us-header5">250%</h3>
            <span className="about-us-caption5">
              <span className="about-us-text27">Company Growth</span>
              <br></br>
            </span>
            <span className="about-us-caption6">
              From the start, our investors, advocates, and clients have been
              instrumental in our growth and progress toward achieving our
              goals.
            </span>
          </div>
          <div className="about-us-stat3">
            <h3 className="about-us-header6">10+</h3>
            <span className="about-us-caption7">
              <span>Years of Experience</span>
              <br></br>
            </span>
            <span className="about-us-caption8">
              Founded in 2020, Gridhack is led by master electricians with over
              10 years of experience and network and marketing advisors with
              more than 5 years in the field.
            </span>
          </div>
        </div>
      </section>
      <Mission
        name={
          <Fragment>
            <span className="about-us-text31">Robert Robledo</span>
          </Fragment>
        }
        text={
          <Fragment>
            <span className="about-us-text32">Testimonial</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="about-us-text33">
              &quot;The quickest and easiest way I&apos;ve ever saved money!
              Amanda was amazing and incredibly helpful. I love how my home
              operates now!&quot;
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="about-us-text34">
              Committed to delivering clean energy solutions
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="about-us-text35">
              <span>Empowering communities through renewable energy</span>
              <br></br>
            </span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="about-us-text38">
              <span>Supporting families with essential resources</span>
              <br></br>
            </span>
          </Fragment>
        }
        text5={
          <Fragment>
            <span className="about-us-text41">
              <span>
                Building resilient shelters for future natural disasters
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        caption={
          <Fragment>
            <span className="about-us-text44">
              We strive to create a sustainable and brighter future for all. Our
              focus is on providing affordable and efficient solar solutions to
              communities worldwide, helping to light up lives and protect our
              planet.
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <span className="about-us-heading3">
              <span>
                Empowering Communities for a
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="about-us-text46">Sustainable Future</span>
              <br></br>
            </span>
          </Fragment>
        }
        rootClassName="missionroot-class-name"
      ></Mission>
      <Footer rootClassName="footerroot-class-name2"></Footer>
    </div>
  );
};

export default AboutUs;
