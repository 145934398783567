import React from "react";

import "./check.css";

const Check = () => {
  return (
    <div className="check-check">
      <svg viewBox="0 0 1024 1024" className="check-icon1">
        <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
      </svg>
    </div>
  );
};

export default Check;
