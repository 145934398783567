import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Line from "../components/line";
import Check from "../components/check";
import Mission from "../components/mission";
import Customer from "../components/customer";
import Footer from "../components/footer";
import "./home.css";

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Affordable Custom Solar Systems | Gridhack Solar</title>
        <meta
          name="description"
          content="Save on energy bills with PPAs and solar loan options. Our goal is to make solar power accessible,\naffordable, and create a sustainable future for all."
        />
        <meta
          property="og:title"
          content="Affordable Custom Solar Systems | Gridhack Solar"
        />
        <meta
          property="og:description"
          content="Save on energy bills with PPAs and solar loan options. Our goal is to make solar power accessible,\naffordable, and create a sustainable future for all."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/03f42d1d-03f9-45c6-8296-041753989070/fa7cb9e8-4d19-483d-8664-392a15595b0b?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section className="home-hero">
        <header className="home-header10">
          <Navbar
            text={
              <Fragment>
                <span className="home-text10">Learn more: (Coming Soon)</span>
              </Fragment>
            }
            text1={
              <Fragment>
                <span className="home-text11">...</span>
              </Fragment>
            }
            text2={
              <Fragment>
                <span className="home-text12">...</span>
              </Fragment>
            }
            text3={
              <Fragment>
                <span className="home-text13">...</span>
              </Fragment>
            }
            text4={
              <Fragment>
                <Link to="/" className="home-navlink1">
                  <span>Home</span>
                  <br></br>
                </Link>
              </Fragment>
            }
            text5={
              <Fragment>
                <span className="home-text16">Contact</span>
              </Fragment>
            }
            text6={
              <Fragment>
                <span className="home-text17">About Us</span>
              </Fragment>
            }
            rootClassName="navbarroot-class-name1"
          ></Navbar>
        </header>
        <video
          src="https://gridhack.io/wp-content/uploads/2024/02/Gridhack-With-Audio.mp4"
          loop="true"
          muted="true"
          poster="https://gridhack.io/wp-content/uploads/2024/02/Gridhack-With-Audio.mp4"
          preload="auto"
          autoPlay="true"
          playsInline="true"
          className="home-video"
        ></video>
        <div className="home-hero-content">
          <div className="home-header-container1">
            <div className="home-header11">
              <h1 id="hero-title" className="home-heading1">
                <span>
                  Saving the Grid,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text19">Saving the World</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption1">
              Making solar accessible and affordable for everyone.
            </p>
          </div>
          <Link to="/contact" className="home-navlink2 button">
            <span>
              <span>Get started today</span>
              <br></br>
            </span>
          </Link>
        </div>
      </section>
      <section className="home-note">
        <h2 className="home-caption2">
          A simpler way to switch to clean energy.
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content10">
          <div className="home-stat1">
            <h3 className="home-header12">$1,500</h3>
            <span className="home-caption3">Average Yearly Savings</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header13">1 Million</h3>
            <span className="home-caption4">
              Households switch to solar each year
            </span>
          </div>
          <div className="home-stat3">
            <h3 className="home-header14">7-10 years</h3>
            <span className="home-caption5">
              Average time a system &quot;pays itself off&quot;
            </span>
          </div>
        </div>
      </section>
      <section className="home-intro">
        <div className="home-content11">
          <div className="home-header15">
            <div className="home-header16">
              <h2 className="home-heading2">
                <span>
                  Custom solar systems designed to
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text25">fit your unique needs.</span>
                <br></br>
              </h2>
            </div>
            <p className="home-caption6">
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </p>
            <p className="home-caption7">
              Your home or building is one-of-a-kind - shouldn&apos;t its solar
              system be too? Our custom design process ensures that your energy
              solution fits perfectly with your space and budget needs.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </p>
          </div>
          <div className="home-testimonial">
            <div className="home-content12">
              <span className="home-text27">Testimonial</span>
              <p className="home-text28">
                “Gridhack did an amazing job on my 6.4 kW system. My bills have
                been consistent, and I’m seeing great savings!”
              </p>
            </div>
            <div className="home-information">
              <div className="home-author">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1464961968964-a80a9b51f3d9?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDY5fHx3b21hbnxlbnwwfHx8fDE3MjU0MjgyMzl8MA&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon"
                />
                <span className="home-name">Katie Giron</span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-images">
          <div className="home-square"></div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1707301280408-8a9158f7613d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDM4fHxleHBsYWlufGVufDB8fHx8MTcyNTQyNjgwNHww&amp;ixlib=rb-4.0.3&amp;w=1400"
            className="home-image"
          />
        </div>
      </section>
      <section className="home-objectives">
        <div className="home-content13">
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text29">Savings</h3>
              <p className="home-text30">
                We help homeowners like you save thousands on energy bills with
                solar power. By harnessing the sun&apos;s energy, you can lower
                your utility costs and increase your home&apos;s value.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text31">Simplicity</h3>
              <p className="home-text32">
                We make going solar easy and hassle-free. Our team takes care of
                everything from consultation to installation, so you can focus
                on saving money and reducing your carbon footprint.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective3">
              <h3 className="home-text33">Dedication</h3>
              <p className="home-text34">
                We&apos;re dedicated to providing exceptional customer service
                and support from initial consultation to years after
                installation. Our goal is to help you achieve a more sustainable
                future for yourself and our planet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home-comparision">
        <div className="home-header-container2">
          <div className="home-header17">
            <h2 className="home-heading3">
              <span>System Comparison</span>
              <br></br>
            </h2>
          </div>
          <p className="home-caption8">
            Understand the pros and cons of Power Purchase Agreements (PPAs),
            Loans, and Full Price Solar Systems
          </p>
        </div>
        <div className="home-table">
          <div className="home-row10">
            <div className="home-headers1">
              <span className="home-text37">Placeholder</span>
            </div>
            <div className="home-plans1">
              <div className="home-row11">
                <span className="home-text38">PPA</span>
                <span className="home-text39">Loans</span>
                <span className="home-text40">Full Price</span>
              </div>
            </div>
          </div>
          <div className="home-row12">
            <div className="home-headers2">
              <span className="home-text41">Upfront Cost*</span>
            </div>
            <div className="home-plans2">
              <div className="home-row13">
                <div className="value">
                  <span className="home-text42">~$0</span>
                </div>
                <div className="value">
                  <span className="home-text43">~$0</span>
                </div>
                <div className="value">
                  <span className="home-text44">$10,000+</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row14">
            <div className="home-headers3">
              <span className="home-text45">Potential Savings*</span>
            </div>
            <div className="home-plans3">
              <div className="home-row15">
                <div className="value">
                  <span className="home-text46">~$50/mo</span>
                </div>
                <div className="value">
                  <span className="home-text47">~$20/mo</span>
                </div>
                <div className="value">
                  <span className="home-text48">$150+</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row16">
            <div className="home-headers4">
              <span className="home-text49">Maintenance Responsibility</span>
            </div>
            <div className="home-plans4">
              <div className="home-row17">
                <div className="value">
                  <span className="home-text50">100% Coverage</span>
                </div>
                <div className="value">
                  <span className="home-text51">100% Coverage w/ Gridhack</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row18">
            <div className="home-headers5">
              <span className="home-text52">Warranty Coverage</span>
            </div>
            <div className="home-plans5">
              <div className="home-row19">
                <div className="value">
                  <span className="home-text53">Full</span>
                </div>
                <div className="value">
                  <span className="home-text54">Full w/ Gridhack</span>
                </div>
                <div className="value">
                  <span className="home-text55">Limited</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row20">
            <div className="home-headers6">
              <span className="home-text56">Transferability</span>
            </div>
            <div className="home-plans6">
              <div className="home-row21">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row22">
            <div className="home-headers7">
              <span className="home-text57">Ease of Installation</span>
            </div>
            <div className="home-plans7">
              <div className="home-row23">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row24">
            <div className="home-headers8"></div>
            <div className="home-plans8">
              <div className="home-row25">
                <div className="value">
                  <Link to="/" className="home-navlink3">
                    Learn more (Coming Soon)
                  </Link>
                </div>
                <div className="value">
                  <Link to="/" className="home-navlink4">
                    Learn more (Coming Soon)
                  </Link>
                </div>
                <div className="value">
                  <Link to="/" className="home-navlink5">
                    Learn more (Coming Soon)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="home-text58">
          *Not actual estimate values. Real values depend on your unique system
          and current energy bills.
        </span>
      </section>
      <div className="home-questions">
        <h1 className="home-text59">
          <span>Still have questions about solar power or our process?</span>
          <br></br>
        </h1>
        <p className="home-caption9">
          We&apos;ve got answers! Get in touch with us today to learn more.
        </p>
        <Link to="/contact" className="home-navlink6 button">
          <span>
            <span>Contact Us</span>
            <br></br>
          </span>
        </Link>
      </div>
      <Mission
        name={
          <Fragment>
            <span className="home-text65">Robert Robledo</span>
          </Fragment>
        }
        text={
          <Fragment>
            <span className="home-text66">Testimonial</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text67">
              &quot;The quickest and easiest way I&apos;ve ever saved money!
              Amanda was amazing and incredibly helpful. I love how my home
              operates now!&quot;
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text68">
              Committed to delivering clean energy solutions
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="home-text69">
              <span>Empowering communities through renewable energy</span>
              <br></br>
            </span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="home-text72">
              <span>Supporting families with essential resources</span>
              <br></br>
            </span>
          </Fragment>
        }
        text5={
          <Fragment>
            <span className="home-text75">
              <span>
                Building resilient shelters for future natural disasters
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        caption={
          <Fragment>
            <span className="home-text78">
              We strive to create a sustainable and brighter future for all. Our
              focus is on providing affordable and efficient solar solutions to
              communities worldwide, helping to light up lives and protect our
              planet.
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <span className="home-heading4">
              <span>
                Empowering Communities for a
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text80">Sustainable Future</span>
              <br></br>
            </span>
          </Fragment>
        }
      ></Mission>
      <div className="home-customer-container">
        <Customer
          name={
            <Fragment>
              <span className="home-text82">Caitlin Sutherland</span>
            </Fragment>
          }
          name1={
            <Fragment>
              <span className="home-text83">Lulani Dennehy</span>
            </Fragment>
          }
          name2={
            <Fragment>
              <span className="home-text84">Jack Meifert</span>
            </Fragment>
          }
          quote={
            <Fragment>
              <span className="home-quote1">
                <span>
                  &quot;Von was incredibly helpful throughout the entire
                  process, from applying for my solar panel system to the final
                  installation. He handled all the legwork, including
                  coordinating with my HOA and CPS to ensure everything was
                  inspected and approved. The system works flawlessly, and
                  I&apos;ve already noticed a significant drop in my electric
                  bill. I would highly recommend him to anyone!&quot;
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          quote1={
            <Fragment>
              <span className="home-quote2">
                <span className="home-text87">
                  &quot;Great company to work with! They&apos;ve installed solar
                  for a couple of my referrals, and the experience has been
                  fantastic. Von has been incredibly supportive, driven by his
                  commitment to saving the planet and his charitable
                  efforts.&quot;
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          quote2={
            <Fragment>
              <span className="home-quote3">
                <span className="home-text89">
                  &quot;A much better option than anything else I was quoted. I
                  was very skeptical about solar companies until I met the
                  team—they walked me through every detail. Customer support is
                  a 10/10. Highly recommend speaking with Arianna!&quot;
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          heading={
            <Fragment>
              <span className="home-heading5">
                <span className="home-text91">
                  Testimonials: Powered by Solar,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text92">Inspired by You</span>
                <br></br>
              </span>
            </Fragment>
          }
          location={
            <Fragment>
              <span className="home-text94">Customer</span>
            </Fragment>
          }
          location1={
            <Fragment>
              <span className="home-text95">Referral</span>
            </Fragment>
          }
          location2={
            <Fragment>
              <span className="home-text96">Customer</span>
            </Fragment>
          }
        ></Customer>
      </div>
      <section className="home-faq">
        <div className="home-header18">
          <h2 id="faq" className="home-heading6">
            <span>Frequently asked </span>
            <span className="home-text98">Questions</span>
            <br></br>
          </h2>
        </div>
        <div className="home-content14">
          <div className="home-column1">
            <div className="home-element1">
              <h3 className="home-header19">How does solar energy work?</h3>
              <p className="home-content15">
                Solar panels capture sunlight and convert it into electricity
                using photovoltaic cells. This electricity can be used to power
                your home or business, reducing your reliance on the grid and
                lowering your energy bills. Any excess energy generated can
                often be sent back to the grid, earning you credits or further
                reducing your electricity costs.
              </p>
            </div>
            <div className="home-element2">
              <h3 className="home-header20">
                What are the benefits of installing solar panels?
              </h3>
              <p className="home-content16">
                Solar panels significantly reduce your electricity bills, lower
                your carbon footprint, and can increase your property value.
                Additionally, solar energy is a renewable resource, meaning it
                helps reduce dependency on fossil fuels and contributes to a
                more sustainable future. Over time, the savings on your energy
                bills can pay back the initial investment, leading to long-term
                financial benefits.
              </p>
            </div>
            <div className="home-element3">
              <h3 className="home-header21">
                What is a Solar Power Purchase Agreement (PPA) and how does it
                work?
              </h3>
              <p className="home-content17">
                A Solar PPA is a financial agreement where a developer installs,
                owns, and maintains a solar system on your property. You agree
                to purchase the electricity generated by the system at a fixed
                rate, usually lower than your utility’s retail rate. This
                arrangement allows you to benefit from solar energy without the
                upfront costs or maintenance responsibilities, but you won’t own
                the system.
              </p>
            </div>
          </div>
          <div className="home-column2">
            <div className="home-element4">
              <h3 className="home-header22">
                Are there any upfront costs with a Solar PPA?
              </h3>
              <p className="home-content18">
                No, there are no upfront costs with a PPA, making it an
                attractive option for those who want to adopt solar energy
                without a significant initial investment. You only pay for the
                electricity produced by the solar system, which can offer
                immediate savings on your energy bills. However, since you don’t
                own the system, you won’t benefit directly from tax incentives
                or the potential increase in property value.
              </p>
            </div>
            <div className="home-element5">
              <h3 className="home-header23">
                What is a solar loan, and how does it differ from a PPA?
              </h3>
              <p className="home-content19">
                A solar loan allows you to borrow money to purchase and install
                a solar system, which you repay over time at a fixed rate,
                usually lower than your current bill. Unlike a PPA, with a solar
                loan, you own the system and can benefit from tax incentives,
                such as the federal solar tax credit, as well as increased
                property value. Ownership also means you can enjoy the full
                financial benefits once the loan is repaid.
              </p>
            </div>
            <div className="home-element6">
              <h3 className="home-header24">
                What happens at the end of a PPA term or solar loan repayment?
              </h3>
              <p className="home-content20">
                At the end of a PPA term, you may have the option to extend the
                agreement, purchase the system at a fair market value, or have
                it removed from your property. If you choose to buy the system,
                you can continue benefiting from the energy it generates without
                ongoing payments. With a solar loan, once the loan is fully
                repaid, you own the system outright and can enjoy free solar
                energy for the remainder of the system&apos;s lifespan, which
                typically lasts 25-30 years.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer rootClassName="footerroot-class-name"></Footer>
    </div>
  );
};

export default Home;
