import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar.css";

const Navbar = (props) => {
  return (
    <div className={`navbar-container1 ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar">
        <div className="navbar-logo">
          <Link to="/" className="navbar-navlink1">
            <img
              alt={props.brandingAlt}
              src={props.brandingSrc}
              className="navbar-branding"
            />
          </Link>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <div className="navbar-hamburger">
            <img
              alt={props.iconAlt}
              src={props.iconSrc}
              className="navbar-icon1"
            />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="navbar-nav1"
          >
            <div className="navbar-container2">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="navbar-image"
              />
              <div data-thq="thq-close-menu" className="navbar-menu-close">
                <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="navbar-nav2"
            >
              <Link to="/">
                {props.text4 ?? (
                  <Fragment>
                    <Link to="/" className="navbar-navlink8">
                      <span>Home</span>
                      <br></br>
                    </Link>
                  </Fragment>
                )}
              </Link>
              <Link to="/contact">
                {props.text5 ?? (
                  <Fragment>
                    <span className="navbar-text8">Contact</span>
                  </Fragment>
                )}
              </Link>
              <Link to="/about-us">
                {props.text6 ?? (
                  <Fragment>
                    <span className="navbar-text7">About Us</span>
                  </Fragment>
                )}
              </Link>
            </nav>
          </div>
          <div className="navbar-learn-more">
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="navbar-text2">
                    Learn more (Coming Soon):
                  </span>
                </Fragment>
              )}
            </span>
            <Link to="/" className="navbar-navlink5">
              {props.text1 ?? (
                <Fragment>
                  <span className="navbar-text9">...</span>
                </Fragment>
              )}
            </Link>
            <Link to="/" className="navbar-navlink6">
              {props.text2 ?? (
                <Fragment>
                  <span className="navbar-text6">...</span>
                </Fragment>
              )}
            </Link>
            <Link to="/" className="navbar-navlink7">
              {props.text3 ?? (
                <Fragment>
                  <span className="navbar-text5">...</span>
                </Fragment>
              )}
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

Navbar.defaultProps = {
  rootClassName: "",
  text: undefined,
  brandingSrc: "/branding_gh-1400w.png",
  text4: undefined,
  iconAlt: "image",
  text3: undefined,
  text2: undefined,
  imageAlt: "image",
  brandingAlt: "image",
  text6: undefined,
  text5: undefined,
  text1: undefined,
  iconSrc: "/hamburger-menu.svg",
  imageSrc: "/branding_gh-1400w.png",
};

Navbar.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.element,
  brandingSrc: PropTypes.string,
  text4: PropTypes.element,
  iconAlt: PropTypes.string,
  text3: PropTypes.element,
  text2: PropTypes.element,
  imageAlt: PropTypes.string,
  brandingAlt: PropTypes.string,
  text6: PropTypes.element,
  text5: PropTypes.element,
  text1: PropTypes.element,
  iconSrc: PropTypes.string,
  imageSrc: PropTypes.string,
};

export default Navbar;
