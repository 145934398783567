import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./footer.css";

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-footer">
        <div className="footer-content">
          <div className="footer-main">
            <div className="footer-branding">
              <img
                alt="image"
                src="/external/branding_gh-1400w-700h.png"
                className="footer-image"
              />
              <span className="footer-text1">
                <span>
                  Let’s light the way to a better tomorrow, one solar panel at a
                  time.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="footer-links">
              <div className="footer-column1">
                <span className="footer-header1">Company</span>
                <div className="footer-list1">
                  <Link to="/" className="footer-navlink1">
                    Home
                  </Link>
                  <Link to="/contact" className="footer-navlink2">
                    Contact
                  </Link>
                  <Link to="/about-us" className="footer-navlink3">
                    About Us
                  </Link>
                </div>
              </div>
              <div className="footer-column2">
                <span className="footer-header2">Learn More (Coming Soon)</span>
                <div className="footer-list2">
                  <Link to="/" className="footer-navlink4">
                    ...
                  </Link>
                  <Link to="/" className="footer-navlink5">
                    ...
                  </Link>
                  <Link to="/" className="footer-navlink6">
                    ...
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <span className="footer-text4">
              © 2024 Gridhack - All rights reserved
            </span>
            <Link to="/contact" className="footer-navlink7 button">
              <span>
                <span>Contact Us</span>
                <br></br>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: "",
};

Footer.propTypes = {
  rootClassName: PropTypes.string,
};

export default Footer;
