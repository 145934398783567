import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./contact-form.css";

const ContactForm = (props) => {
  return (
    <div
      className={`contact-form-contact1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="contact-form-max-width thq-section-max-width thq-flex-row">
        <div className="contact-form-section-title thq-flex-column">
          <div className="contact-form-content">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="contact-form-text19 thq-heading-2">
                    Get in Touch
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.content1 ?? (
                <Fragment>
                  <span className="contact-form-text18 thq-body-small">
                    We’re here to help you make the switch to clean, renewable
                    energy. Whether you have questions about our solar
                    solutions, want to learn more about our Power Purchase
                    Agreements (PPAs), or need assistance with financing
                    options, our team is ready to assist you.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <form
          method="POST"
          action="https://formsubmit.co/getstarted@gridhack.org"
          encType="application/x-www-form-urlencoded"
          className="contact-form-form thq-flex-column"
        >
          <div className="contact-form-container1">
            <div className="contact-form-input1">
              <label
                htmlFor="contact-form-7-first-name"
                className="contact-form-text12 thq-body-small"
              >
                First name
              </label>
              <input
                type="text"
                id="contact-form-7-first-name"
                name="first-name"
                required="true"
                placeholder="First Name"
                className="thq-input"
              />
            </div>
            <div className="contact-form-input2">
              <label
                htmlFor="contact-form-7-last-name"
                className="contact-form-text13 thq-body-small"
              >
                Last name
              </label>
              <input
                type="text"
                id="contact-form-7-last-name"
                name="last-name"
                required="true"
                placeholder="Last Name"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form-container2">
            <div className="contact-form-input3">
              <label
                htmlFor="contact-form-7-email"
                className="contact-form-text14 thq-body-small"
              >
                Email
              </label>
              <input
                type="email"
                id="contact-form-7-email"
                name="email"
                required="true"
                placeholder="Email"
                className="thq-input"
              />
            </div>
            <div className="contact-form-input4">
              <label
                htmlFor="contact-form-7-phone"
                className="contact-form-text15 thq-body-small"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="contact-form-7-phone"
                name="phone"
                multiple="phone"
                placeholder="Phone Number"
                autoComplete="tel"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form-container3">
            <div className="contact-form-input5">
              <label
                htmlFor="contact-form-7-options"
                className="contact-form-text16 thq-body-small"
              >
                Your Location
              </label>
              <select
                id="contact-form-7-options"
                name="region"
                required="true"
                placeholder="Select one"
                className="contact-form-select thq-select"
              >
                <option value="colorado">Colorado</option>
                <option value="texas">Texas</option>
              </select>
            </div>
          </div>
          <div className="contact-form-container4">
            <div className="contact-form-input6">
              <label
                htmlFor="contact-form-7-message"
                className="contact-form-text17 thq-body-small"
              >
                Message
              </label>
              <textarea
                id="contact-form-7-message"
                name="message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
              ></textarea>
            </div>
          </div>
          <button
            type="submit"
            className="contact-form-button thq-button-filled"
          >
            <span>
              {props.action ?? (
                <Fragment>
                  <span className="contact-form-text20 thq-body-small">
                    Contact Us
                  </span>
                </Fragment>
              )}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

ContactForm.defaultProps = {
  content1: undefined,
  heading1: undefined,
  action: undefined,
  rootClassName: "",
};

ContactForm.propTypes = {
  content1: PropTypes.element,
  heading1: PropTypes.element,
  action: PropTypes.element,
  rootClassName: PropTypes.string,
};

export default ContactForm;
