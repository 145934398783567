import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import AboutUs from "./views/about-us";
import Contact from "./views/contact";
import Home from "./views/home";
import NotFound from "./views/not-found";
import ScrollToTop from "./ScrollToTop"; // Import ScrollToTop

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Include ScrollToTop */}
      <Switch>
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/" exact component={Home} />
        <Route path="/not-found" exact component={NotFound} />
        <Redirect from="*" to="/not-found" />
      </Switch>
    </Router>
  );
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById("app"));
